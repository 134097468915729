import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';

class ReelsBackgroundContainer extends ViewContainer {
  private reelBack = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelback));

  constructor() {
    super();

    this.addChild(this.reelBack);
  }
}

export default ReelsBackgroundContainer;
