import { SlotId } from '../../config';
import { GameMode, ReelId, bonusIds, reelSets } from '../../global.d';
import { setCoinAmount, setSlotConfig } from '../../gql/cache';
import { BaseGameMotionType } from '../../slotMachine/avatarMotion/config';

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};

export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPINS_LVL1 ||
    mode === GameMode.FREE_SPINS_LVL2 ||
    mode === GameMode.FREE_SPINS_LVL3 ||
    mode === GameMode.FREE_SPINS_LVL4 ||
    mode === GameMode.FREE_SPINS_LVL5
  );
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE || mode === GameMode.BUY_FEATURE_MAX;
};

export const isScatter = (slotId: SlotId): boolean => {
  return (
    slotId === SlotId.SC1 ||
    slotId === SlotId.SC2 ||
    slotId === SlotId.SC3 ||
    slotId === SlotId.SC4 ||
    slotId === SlotId.SC5 ||
    slotId === SlotId.SC6 ||
    slotId === SlotId.SC7
  );
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  if (bonusIds[GameMode.FREE_SPINS_LVL1] === bonusId) return GameMode.FREE_SPINS_LVL1;
  if (bonusIds[GameMode.FREE_SPINS_LVL2] === bonusId) return GameMode.FREE_SPINS_LVL2;
  if (bonusIds[GameMode.FREE_SPINS_LVL3] === bonusId) return GameMode.FREE_SPINS_LVL3;
  if (bonusIds[GameMode.FREE_SPINS_LVL4] === bonusId) return GameMode.FREE_SPINS_LVL4;
  if (bonusIds[GameMode.FREE_SPINS_LVL5] === bonusId) return GameMode.FREE_SPINS_LVL5;
  return GameMode.REGULAR;
};

export const getLevelByGameMode = (gameMode: GameMode): number => {
  type gameModeNumberType = { name: GameMode; num: number };
  const getGameModeNumberList: gameModeNumberType[] = [
    { name: GameMode.FREE_SPINS_LVL1, num: 1 },
    { name: GameMode.FREE_SPINS_LVL2, num: 2 },
    { name: GameMode.FREE_SPINS_LVL3, num: 3 },
    { name: GameMode.FREE_SPINS_LVL4, num: 4 },
    { name: GameMode.FREE_SPINS_LVL5, num: 5 },
  ];

  const result = getGameModeNumberList.find((game) => game.name === gameMode);
  if (result === undefined) {
    return 0;
  } else {
    return result.num;
  }
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return ReelId.REGULAR === reelSetId;
};

export const isBuyFeatureReelSet = (reelSetId: string): boolean => {
  return ReelId.BUY_FEATURE === reelSetId || ReelId.BUY_FEATURE_MAX === reelSetId;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

// TODO 修正必須！！！
export const getWinCoin = (): number => {
  return setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier;
};

export const isMotionLoop = (motionType: BaseGameMotionType): boolean => {
  return motionType === BaseGameMotionType.BonusWin;
};
