import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { EventTypes } from '../../global.d';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { Combos, Icon } from '../d';
import { Slot } from '../slot/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: CallableFunction;

  private payTableContainers: PIXI.Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(icons: Icon[], getSlotByIdFn: CallableFunction) {
    super();
    this.icons = icons;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    eventManager.addListener(EventTypes.DISABLE_PAY_TABLE, (isVisible: boolean) => {
      // console.log('DISABLE_PAY_TABLE', isVisible);
      this.visible = isVisible;
    });
    this.payTableContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payTableData.find((d) => d.slug === slug)?.combos as Combos,
    );

    this.createContainers();
  }

  private createContainers() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot: Slot = this.getSlotById(index) as Slot;
        const icon = this.icons.find((icon) => icon.id === slot.slotId)!;
        const container = new PIXI.Container();
        const rect = new PIXI.Graphics();
        container.width = REEL_WIDTH;
        container.height = SLOT_HEIGHT;
        container.x = this.xPosition(col);
        container.y = this.yPosition(row);
        container.zIndex = 1;
        container.interactive = true;
        rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
        rect.drawRect(0, 0, REEL_WIDTH, SLOT_HEIGHT);
        rect.alpha = 0;
        container.addChild(rect);
        container.on('mousedown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        container.on('touchstart', () => eventManager.emit(EventTypes.SHOW_PAY_TABLE, index));
        const payTable = new MiniPayTable(index, icon, this.combosMap[icon.id]);
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(col: number): number {
    return col * REEL_WIDTH;
  }

  private yPosition(row: number): number {
    return row * SLOT_HEIGHT;
  }

  public setSpinResult(spinResult: Icon[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index]!;
      const payTable = container.children[1] as MiniPayTable;
      payTable.setPayTableData(icon.id, this.combosMap[icon.id]);
    });
  }
}

export default MiniPayTableContainer;
