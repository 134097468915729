import { gql } from '@apollo/client';

import {
  BET,
  BET_RESULT,
  BONUS,
  CLIENT_SLOT_AUTOPLAY_CONDITIONS,
  CLIENT_SLOT_SETTINGS,
  LINE_SET,
  PAGE_INFO,
  REEL_SET,
  SLOT,
  SLOT_ICON,
  SLOT_SETTINGS,
  USER,
  USER_BALANCE,
  USER_BONUS,
} from './fragment';

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isEnabledSpaceSpin @client
    isOpenMenuModal @client
    isOpenBetSettingsModal @client
    isOpenAutoplayModal @client
    isOpenHistoryPopup @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
  }
`;

export const getUserGql = gql`
  ${USER}
  ${USER_BALANCE}
  query GetUser {
    user {
      ...user
      balance {
        ...userBalance
      }
    }
  }
`;

export const getInfoGql = gql`
  query GetInfo {
    getInfo {
      ip
      country
      city
    }
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;
export const getGameModeGql = gql`
  query GetAutoSpin {
    gameMode @client
  }
`;

export const getSlotConfigGql = gql`
  query GetSlotConfig {
    slotConfig @client {
      id
      lines
    }
  }
`;

export const userBonusBetsGql = gql`
  ${PAGE_INFO}
  query userBonusBets($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
    }
  }
`;

export const slotHistoryGql = gql`
  ${PAGE_INFO}
  ${BET}
  ${BET_RESULT}
  query slotHistory($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
      edges {
        cursor
        node {
          ...bet
          result {
            ...betResult
          }
        }
      }
    }
  }
`;

export const slotBetGql = gql`
  ${BET}
  ${BET_RESULT}
  query bet($input: GetBetInput!) {
    bet(input: $input) {
      ...bet
      result {
        ...betResult
      }
    }
  }
`;

export const getSlotGql = gql`
  ${SLOT}
  ${REEL_SET}
  ${SLOT_ICON}
  ${SLOT_SETTINGS}
  ${CLIENT_SLOT_SETTINGS}
  ${CLIENT_SLOT_AUTOPLAY_CONDITIONS}
  ${LINE_SET}
  query getSlot($input: GetSlotInput!) {
    slot(input: $input) {
      ...slot
      reels {
        ...reelSet
      }
      icons {
        ...slotIcon
      }
      settings {
        ...slotSettings
      }
      clientSettings {
        ...clientSlotSettings
      }
      lineSets {
        ...lineSet
      }
    }
  }
`;

export const getBonuses = gql`
  ${BONUS}
  query GetBonuses($input: GetBonusesInput) {
    bonuses(input: $input) {
      ...bonus
    }
  }
`;

export const getUserBonuses = gql`
  ${USER_BONUS}
  query GetUserBonuses($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      ...userBonus
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
  }
`;

export enum ReelSetType {
  DEFAULT = 'DEFAULT',
  BONUS = 'BONUS',
}
