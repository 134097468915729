import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { calcPercentage, isBaseGameMode } from '../../utils';
import { ColorBFTH, eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class BetBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private isBuyFeaturePopup = false;

  private isFadeOut = false;

  constructor() {
    super('bet');
    this.interactive = !this.isDisabled;
    this.btn.tint = ColorBFTH.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, (isBuyFeaturePopup: boolean) => {
      this.isBuyFeaturePopup = isBuyFeaturePopup;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.isFadeOut = isFadeOut;
      this.handleDisable();
    });
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isBaseGameMode(setGameMode()) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsPopupOpened() ||
        setIsContinueAutoSpinsAfterFeature() ||
        this.isBuyFeaturePopup ||
        this.isFadeOut,
    );
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BET_SETTINGS_POPUP);

      setIsOpenBetSettingsPopup(!setIsOpenBetSettingsPopup());
      setIsOpenAutoplayPopup(false);
      setIsOpenInfoPopup(false);
      setIsOpenHistoryPopup(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      x = this.applicationSize.width - gap;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    // if (this.isLandscapeMode) {}

    this.x = x;
    this.y = y;
  }
}

export default BetBtn;
