import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const levelTextStyle = new PIXI.TextStyle({
  fontFamily: font,
  dropShadow: true,
  dropShadowAlpha: 1,
  dropShadowColor: '#000000',
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: '#ffff00',
  stroke: '#FF0000',
  strokeThickness: 4,
  fontSize: 25.5,
  fontStyle: 'italic',
  fontWeight: 'bold',
  lineJoin: 'round',
});

export const maxLevelTextStyle = new PIXI.TextStyle({
  fontFamily: font,
  fill: ['#ff0000', '#800000'],
  fontSize: 48,
  stroke: '#FFFF80',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowAlpha: 1,
  dropShadowBlur: 0,
  dropShadowColor: '#000000',
  dropShadowDistance: 4,
  fontStyle: 'italic',
  fontWeight: 'bold',
  lineJoin: 'round',
});

export const textStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: 0xfff000,
  fontFamily: font,
  fontSize: 50,
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
  fontStyle: 'italic',
  fontWeight: 'bolder',
};

export const spinsStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: 0xfff000,
  fontFamily: font,
  fontSize: 50,
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
  letterSpacing: 0,
  fontStyle: 'italic',
  fontWeight: 'bolder',
};

export const countDownTextDefaultStyle: Partial<PIXI.ITextStyle> = {
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: '#a6a6a6',
  fontFamily: 'Helvetica',
  fontSize: 500,
  fontWeight: 'bolder',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 10,
};

export const countDownTextRainbowStyle: Partial<PIXI.ITextStyle> = {
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  fill: ['#ff0000', '#ff9500', '#fbff00', '#11ff00', '#05fbff', '#3700ff', '#ff00dd'],
  fillGradientStops: [0.2],
  fontFamily: 'Helvetica',
  fontSize: 500,
  fontWeight: 'bolder',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 10,
};
