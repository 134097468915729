import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  BANNER_POSITION_X,
  BANNER_POSITION_Y,
  MESSAGE_BANNER_BTN_SCALE,
  MESSAGE_BANNER_BTN_X,
  MESSAGE_BANNER_BTN_Y,
  MESSAGE_BANNER_DESCRIPTION_X,
  MESSAGE_BANNER_DESCRIPTION_Y,
  MESSAGE_BANNER_MAX_ARROW_SPRITE_SCALE,
  MESSAGE_BANNER_MAX_ARROW_SPRITE_X,
  MESSAGE_BANNER_MAX_ARROW_SPRITE_Y,
  MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE,
  MESSAGE_BANNER_MAX_LEVEL_BTN_SCALE,
  MESSAGE_BANNER_MAX_LEVEL_BTN_X,
  MESSAGE_BANNER_MAX_LEVEL_BTN_Y,
  MESSAGE_BANNER_MAX_LEVEL_SPINS_SCALE,
  MESSAGE_BANNER_MAX_LEVEL_SPINS_X,
  MESSAGE_BANNER_MAX_LEVEL_SPINS_Y,
  MESSAGE_BANNER_MAX_LEVEL_TITLE_SCALE,
  MESSAGE_BANNER_MAX_LEVEL_TITLE_X,
  MESSAGE_BANNER_MAX_LEVEL_TITLE_Y,
  MESSAGE_BANNER_MAX_SYMBOL_A_SCALE,
  MESSAGE_BANNER_MAX_SYMBOL_A_X,
  MESSAGE_BANNER_MAX_SYMBOL_A_Y,
  MESSAGE_BANNER_MAX_SYMBOL_B_X,
  MESSAGE_BANNER_MAX_SYMBOL_B_Y,
  MESSAGE_BANNER_MAX_SYMBOL_C_X,
  MESSAGE_BANNER_MAX_SYMBOL_C_Y,
  MESSAGE_BANNER_MAX_SYMBOL_D_X,
  MESSAGE_BANNER_MAX_SYMBOL_D_Y,
  MESSAGE_BANNER_MAX_SYMBOL_E_X,
  MESSAGE_BANNER_MAX_SYMBOL_E_Y,
  MESSAGE_BANNER_SPINS_SCALE,
  MESSAGE_BANNER_SPINS_X,
  MESSAGE_BANNER_SPINS_Y,
  MESSAGE_BANNER_TITLE_SCALE,
  MESSAGE_BANNER_TITLE_X,
  MESSAGE_BANNER_TITLE_Y,
} from './config';
import { btnTextStyle, descriptionTextStyle, subtitleTextStyle, titleTextStyle } from './textStyles';

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title!: PIXI.Text;

  private description!: PIXI.Text;

  private btn!: PIXI.Text;

  private spins!: PIXI.Text;

  private arrow = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanerPointer));

  private symbolA = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolA));
  private symbolB = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolB));
  private symbolC = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolC));
  private symbolD = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolD));
  private symbolE = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolE));

  constructor(props: MessageBannerProps) {
    super();
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.zIndex = 10;
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner();
    this.addChild(this.banner);

    if (props.isLevelMax) {
      this.initLevelMaxText(props);
      this.initLevelMaxSprite();
      this.initMaxLevelAddChild();
    } else {
      this.initText(props);
      this.init();
    }

    setIsOpenedMessageBanner(true);
  }

  private init(): void {
    this.addChild(this.title);
    this.addChild(this.description);
    this.addChild(this.btn);
    this.addChild(this.spins);
  }

  private initText(props: MessageBannerProps): void {
    this.title = this.initTitle(props.title);
    this.spins = this.initSpins(props.freeSpinsSubtitle);
    this.description = this.initDescription(props.subTitle);
    this.btn = this.initBtn(props.btnText);
  }

  private initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    return banner;
  }

  private initTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.resolution = 1;
    title.visible = !!titleText;
    title.anchor.set(0.5);
    title.position.set(MESSAGE_BANNER_TITLE_X, MESSAGE_BANNER_TITLE_Y);
    title.scale.set(MESSAGE_BANNER_TITLE_SCALE);
    updateTextScale(title, this.banner.width - 150, 200);
    return title;
  }

  private initSpins(freeSpinsSubtitle?: string): PIXI.Text {
    const spins = new PIXI.Text(freeSpinsSubtitle, subtitleTextStyle);
    spins.resolution = 1;
    spins.visible = !!spins;
    spins.anchor.set(0.5);
    spins.position.set(MESSAGE_BANNER_SPINS_X, MESSAGE_BANNER_SPINS_Y);
    spins.scale.set(MESSAGE_BANNER_SPINS_SCALE);
    updateTextScale(spins, this.banner.width - 150, 200);
    return spins;
  }

  private initDescription(descriptionText?: string): PIXI.Text {
    const description = new PIXI.Text(descriptionText, descriptionTextStyle);
    description.resolution = 1;
    description.visible = !!descriptionText;
    description.anchor.set(0.5);
    description.position.set(MESSAGE_BANNER_DESCRIPTION_X, MESSAGE_BANNER_DESCRIPTION_Y);
    updateTextScale(description, this.banner.width - 150, 200);
    return description;
  }

  private initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5);
    btn.position.set(MESSAGE_BANNER_BTN_X, MESSAGE_BANNER_BTN_Y);
    btn.scale.set(MESSAGE_BANNER_BTN_SCALE);
    updateTextScale(btn, this.banner.width - 150, 200);
    return btn;
  }

  private initLevelMaxText(props: MessageBannerProps): void {
    this.title = this.initMaxLevelTitle(props.title);
    this.spins = this.initMaxLevelSpins(props.freeSpinsSubtitle);
    this.btn = this.initMaxLevelBtn(props.btnText);
  }

  private initLevelMaxSprite(): void {
    this.initArrow();
    this.initLeftSymbol();
    this.initRightSymbol();
  }

  private initMaxLevelAddChild(): void {
    this.addChild(this.title);
    this.addChild(this.btn);
    this.addChild(this.spins);
  }

  private initMaxLevelTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.resolution = 1;
    title.visible = !!titleText;
    title.anchor.set(0.5);
    title.position.set(MESSAGE_BANNER_MAX_LEVEL_TITLE_X, MESSAGE_BANNER_MAX_LEVEL_TITLE_Y);
    title.scale.set(MESSAGE_BANNER_MAX_LEVEL_TITLE_SCALE);
    if (title.width > this.banner.width - 150) title.width = this.banner.width - 150;
    return title;
  }

  private initMaxLevelSpins(freeSpinsSubtitle?: string): PIXI.Text {
    const spins = new PIXI.Text(freeSpinsSubtitle, subtitleTextStyle);
    spins.resolution = 1;
    spins.visible = !!spins;
    spins.anchor.set(0.5);
    spins.position.set(MESSAGE_BANNER_MAX_LEVEL_SPINS_X, MESSAGE_BANNER_MAX_LEVEL_SPINS_Y);
    spins.scale.set(MESSAGE_BANNER_MAX_LEVEL_SPINS_SCALE);
    if (spins.width > this.banner.width - 150) spins.width = this.banner.width - 150;
    return spins;
  }

  private initMaxLevelBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5);
    btn.position.set(MESSAGE_BANNER_MAX_LEVEL_BTN_X, MESSAGE_BANNER_MAX_LEVEL_BTN_Y);
    btn.scale.set(MESSAGE_BANNER_MAX_LEVEL_BTN_SCALE);
    updateTextScale(btn, this.banner.width - 150, 200);
    return btn;
  }

  private initArrow(): void {
    this.arrow.anchor.set(0.5);
    this.arrow.position.set(MESSAGE_BANNER_MAX_ARROW_SPRITE_X, MESSAGE_BANNER_MAX_ARROW_SPRITE_Y);
    this.arrow.scale.set(MESSAGE_BANNER_MAX_ARROW_SPRITE_SCALE);
    this.addChild(this.arrow);
  }

  private initLeftSymbol(): void {
    this.symbolB.anchor.set(0.5);
    this.symbolC.anchor.set(0.5);
    this.symbolD.anchor.set(0.5);
    this.symbolE.anchor.set(0.5);

    this.symbolB.position.set(MESSAGE_BANNER_MAX_SYMBOL_B_X, MESSAGE_BANNER_MAX_SYMBOL_B_Y);
    this.symbolC.position.set(MESSAGE_BANNER_MAX_SYMBOL_C_X, MESSAGE_BANNER_MAX_SYMBOL_C_Y);
    this.symbolD.position.set(MESSAGE_BANNER_MAX_SYMBOL_D_X, MESSAGE_BANNER_MAX_SYMBOL_D_Y);
    this.symbolE.position.set(MESSAGE_BANNER_MAX_SYMBOL_E_X, MESSAGE_BANNER_MAX_SYMBOL_E_Y);

    this.symbolB.scale.set(MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE);
    this.symbolC.scale.set(MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE);
    this.symbolD.scale.set(MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE);
    this.symbolE.scale.set(MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE);

    this.addChild(this.symbolB);
    this.addChild(this.symbolC);
    this.addChild(this.symbolD);
    this.addChild(this.symbolE);
  }

  private initRightSymbol(): void {
    this.symbolA.anchor.set(0.5);
    this.symbolA.position.set(MESSAGE_BANNER_MAX_SYMBOL_A_X, MESSAGE_BANNER_MAX_SYMBOL_A_Y);
    this.symbolA.scale.set(MESSAGE_BANNER_MAX_SYMBOL_A_SCALE);
    this.addChild(this.symbolA);
  }

  public handleDestroy(): void {
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
    setIsOpenedMessageBanner(false);
  }
}

export default MessageBanner;
