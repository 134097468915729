import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isBaseGameMode, isBuyFeatureMode } from '../../utils';
import { GAME_CONTAINER_WIDTH, GAME_TITLE_LOGO_Y_POSITION, eventManager } from '../config';

class GameTitle extends PIXI.Sprite {
  constructor() {
    super(PIXI.Texture.from(ResourceTypes.titlelogo));

    this.anchor.set(0.5);
    this.x = GAME_CONTAINER_WIDTH / 2;
    this.y = GAME_TITLE_LOGO_Y_POSITION;

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.setVisible.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.setVisible.bind(this));
  }

  private setVisible(settings: { mode: GameMode }): void {
    if (isBaseGameMode(settings.mode) || isBuyFeatureMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
}

export default GameTitle;
