import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import { spinsStyle, textStyle } from '../gameView/config';

import { FREE_SPIN_ANIMATION_DELAY, FREE_SPIN_ANIMATION_LOOP, FREE_SPIN_ANIMATION_SCALE } from './config';

export class FreeSpinsCounter extends ViewContainer {
  private base: PIXI.Sprite;

  private textContainer: ViewContainer;

  private titleText: PIXI.Text;

  private spinsText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: number;

  private currentSpin: number;

  constructor(props: FreeSpinsTitleProps) {
    super();
    this.base = this.initBase();

    this.textContainer = new ViewContainer();
    this.titleText = this.initTitleText(props.text);
    this.spinsText = this.initSpinsText(props.spins, props.currentSpin);

    this.spinsAmount = props.spins;
    this.currentSpin = props.currentSpin;

    this.init();

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: number, curr: number, immediately?: boolean): void => this.handleUpdate(spins, curr, immediately!),
    );
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER_FREE_SPIN, (isLandscape: boolean) => {
      if (isLandscape) {
        this.gameContainerResize(10, 1);
      } else {
        this.gameContainerResize(1, 10);
      }
    });
  }

  private init(): void {
    this.addChild(this.base);

    this.textContainer.name = 'text';
    this.textContainer.addChild(this.titleText);
    this.textContainer.addChild(this.spinsText);
    if (this.textContainer.width > 360) this.textContainer.width = 360;
    this.textContainer.pivot.set(this.textContainer.width / 2, 0);
    this.textContainer.position.set(219, 48);
    this.addChild(this.textContainer);
  }

  private initBase(): PIXI.Sprite {
    return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.levelPanel));
  }

  private initTitleText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>(titleText), textStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.x = 130;

    updateTextScale(text, isMobile ? 280 : 280, isMobile ? 200 : 200);
    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): PIXI.Text {
    const spinsText = new PIXI.Text(this.formatSpins(spins, currentSpin), spinsStyle);
    spinsText.resolution = 1;
    spinsText.anchor.set(0.5, 0.5);
    spinsText.x = 335;
    spinsText.scale.set(this.titleText.scale.x, this.titleText.scale.y);

    return spinsText;
  }

  private handleUpdate(spins: number, currentSpin: number, _immediately: boolean): void {
    if (this.spinsText.text === this.formatSpins(spins, currentSpin)) return;
    this.spinsText.text = this.formatSpins(spins, currentSpin);

    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
      1,
      this.titleText.scale.x,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin !== undefined ? currentSpin : this.currentSpin} / ${
      spins !== undefined ? spins : this.spinsAmount
    }`;
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    eventManager.removeListener(EventTypes.RESIZE_GAME_CONTAINER_FREE_SPIN);
    // eventManager.emit(EventTypes.HANDLE_ACTIVE_FREE_SPINS_GAME, true);
  }

  private gameContainerResize(width: number, height: number): void {
    if (width > height) {
      this.position.set(528, 48);
    } else {
      this.position.set(617, 153);
    }
  }
}
export default FreeSpinsCounter;
