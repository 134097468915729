import { isMobile } from 'mobile-device-detect';

import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight } from '../../utils';
import ViewContainer from '../components/container';
import {
  SAFE_AREA_DESKTOP_BOTTOM_PADDING,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_MOBILE_BOTTOM_PADDING,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  eventManager,
} from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SHOW_SAFE_AREA, () => (this.visible = true));
  }

  private calculateSize(width: number, height: number): [number, number, number, number, number, number] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomSpace = bottomContainerHeight + bottomPadding;

    const isLandscape = width / height >= 1;

    const ratio = isLandscape
      ? SAFE_AREA_LANDSCAPE_WIDTH / SAFE_AREA_LANDSCAPE_HEIGHT
      : SAFE_AREA_PORTRAIT_WIDTH / SAFE_AREA_PORTRAIT_HEIGHT;

    const isRectangleRatio = +(width / height).toFixed(2) >= +ratio.toFixed(2);
    if (isRectangleRatio) {
      newWidth = height * ratio;
      newHeight = height;
    } else {
      newWidth = width;
      newHeight = width / ratio;
    }

    const pivotX = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_X : SAFE_AREA_PORTRAIT_PIVOT_X;
    const pivotY = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_Y : SAFE_AREA_PORTRAIT_PIVOT_Y;
    const scale = isLandscape ? newWidth / SAFE_AREA_LANDSCAPE_WIDTH : newWidth / SAFE_AREA_PORTRAIT_WIDTH;

    return [newWidth, newHeight, scale, pivotX, pivotY, bottomSpace];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, scale, pivotX, pivotY] = this.calculateSize(width, height);

    this.position.set(Math.max((width - newWidth) / 2, 0), Math.max((height - newHeight) / 2, 0));
    eventManager.emit(
      EventTypes.RESIZE_GAME_CONTAINER,
      newWidth,
      scale * (SLOTS_CONTAINER_HEIGHT - pivotY),
      this.position.x,
      this.position.y,
      scale,
      pivotX,
      pivotY,
    );
  }
}
export default SafeArea;
