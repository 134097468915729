import * as PIXI from 'pixi.js';

import { conversionCountDownAnimationType, getAnimationSelectionTableLot } from '../../anticipation';
import { countDownAnimationType, countDownLotTbl } from '../../anticipation/table';
import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { setNextResult } from '../../gql/cache';
import { getScatterCount } from '../../utils/helper';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import { COUNT_DOWN_ANIMATION_NAME, COUNT_DOWN_DEFAULT_ANIMATION_STEP, COUNT_DOWN_SP_ANIMATION_STEP } from './config';

export class CountDownText extends ViewContainer {
  private countDownAnimation: SpineAnimation | undefined = undefined;

  private countDownNumber: number;

  constructor(props: FreeSpinsTitleProps) {
    super();
    this.countDownNumber = this.formatCountDown(props.spins, props.currentSpin);

    this.init();

    this.visible = false;

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: number, curr: number, immediately?: boolean): void => this.countDownTextUpdate(spins, curr, immediately!),
    );
  }

  private init(): void {
    this.countDownAnimation = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources[COUNT_DOWN_ANIMATION_NAME]!.spineData,
    );

    this.countDownAnimation.spine.x = SLOTS_CONTAINER_WIDTH / 2 - 65;
    this.countDownAnimation.spine.y = SLOTS_CONTAINER_HEIGHT / 2 - 670;
  }

  private startAnimation(countdown: number, isSp: boolean) {
    if (isSp) {
      this.countDownAnimation!.setAnimation(COUNT_DOWN_SP_ANIMATION_STEP[countdown]!, false);
    } else {
      this.countDownAnimation!.setAnimation(COUNT_DOWN_DEFAULT_ANIMATION_STEP[countdown]!, false);
    }

    this.addChild(this.countDownAnimation!.spine);
  }

  private countDownTextUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    if (this.countDownNumber === this.formatCountDown(spins, currentSpin)) return;
    this.countDownNumber = this.formatCountDown(spins, currentSpin);

    if (immediately) return;
    if (spins - currentSpin > 3) return;

    let AnimationPtn = countDownAnimationType.DEFAULT;

    if (Math.max(...getScatterCount(setNextResult()?.bet.result.spinResult!)) >= 2) {
      AnimationPtn = conversionCountDownAnimationType(
        getAnimationSelectionTableLot(setNextResult()!.bet.id, countDownLotTbl),
      );
    }

    if (AnimationPtn === countDownAnimationType.RAINBOW) {
      this.startAnimation(this.countDownNumber, true);
    } else {
      this.startAnimation(this.countDownNumber, false);
    }
    this.visible = true;
  }

  private formatCountDown(spins: number, currentSpin: number): number {
    return spins - currentSpin;
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
  }
}
export default CountDownText;
