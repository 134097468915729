export enum ResourceTypes {
  coin1 = 'coin1',
  coin4 = 'coin4',
  coin8 = 'coin8',
  levelPanel = 'levelPanel',
  messageBanerPointer = 'messageBanerPointer',
  messageBanner = 'messageBanner',
  reelback = 'reelback',
  reelframe = 'reelframe',
  symbolIndicatorA = 'symbolIndicatorA',
  symbolIndicatorB = 'symbolIndicatorB',
  symbolIndicatorC = 'symbolIndicatorC',
  symbolIndicatorD = 'symbolIndicatorD',
  symbolIndicatorE = 'symbolIndicatorE',
  colorMap = 'colorMap',
  displacementMapRepeat = 'displacementMapRepeat',
  lightMap = 'lightMap',
  logo = 'logo',
  petalA = 'petalA',
  petalB = 'petalB',
  petalC = 'petalC',
  slotBg = 'slotBg',
  slotFreespinBgState1 = 'slotFreespinBgState1',
  slotFreespinBgState2 = 'slotFreespinBgState2',
  slotFreespinBgState3 = 'slotFreespinBgState3',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolH = 'symbolH',
  symbolHBlur = 'symbolHBlur',
  symbolI = 'symbolI',
  symbolIBlur = 'symbolIBlur',
  symbolJ = 'symbolJ',
  symbolJBlur = 'symbolJBlur',
  symbolK = 'symbolK',
  symbolKBlur = 'symbolKBlur',
  symbolSc = 'symbolSc',
  symbolWl = 'symbolWl',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonOk = 'buttonOk',
  buyFeatureBtnDisable = 'buyFeatureBtnDisable',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureMoon = 'buyFeatureMoon',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeatureSakura = 'buyFeatureSakura',
  buyFeatureFsBtnHover = 'buyFeatureFsBtnHover',
  buyFeatureFsBtnNotActive = 'buyFeatureFsBtnNotActive',
  buyFeatureFsBtnPressed = 'buyFeatureFsBtnPressed',
  buyFeatureFsBtn = 'buyFeatureFsBtn',
  buyFeatureMlfsBtnHover = 'buyFeatureMlfsBtnHover',
  buyFeatureMlfsBtnNotActive = 'buyFeatureMlfsBtnNotActive',
  buyFeatureMlfsBtnPressed = 'buyFeatureMlfsBtnPressed',
  buyFeatureMlfsBtn = 'buyFeatureMlfsBtn',
  infoPaylines = 'infoPaylines',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  introBg = 'introBg',
  popupBg = 'popupBg',
}
