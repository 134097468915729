export interface moveParam {
  aspectMax: number;
  aspectMin: number;
  cvsw: number;
  cvsh: number;
  imgBaseSizeW: number;
  imgBaseSizeH: number;
  gravityMax: number;
  gravityMin: number;
  angleAdd: number;
  wind: number;
  newWind: number;
  windMax: number;
  windMin: number;
}

export const MAX_IMAGE_COUNT = 200;
export const IMAGE_COUNT_1 = MAX_IMAGE_COUNT / 4;
export const IMAGE_COUNT_2 = MAX_IMAGE_COUNT / 3;
export const IMAGE_COUNT_3 = MAX_IMAGE_COUNT / 2;
export const ANIMATION_DELAY = 10;
export const WIND_CHANGE_COUNT = 6000 / ANIMATION_DELAY;
export const WIND_ACCELERATION_SPEED = 0.1;
const IMAGE_SCALE = 4;
const IMAGE_WEIGHT = IMAGE_SCALE;

export const moveParameter: moveParam = {
  aspectMax: 1.5,
  aspectMin: 0.5,
  cvsw: 2500,
  cvsh: 5000,
  imgBaseSizeW: 15 * IMAGE_SCALE,
  imgBaseSizeH: 15 * IMAGE_SCALE,
  gravityMax: 2,
  gravityMin: 2,
  angleAdd: 1.0,
  wind: 10 * IMAGE_WEIGHT,
  newWind: 15 * IMAGE_WEIGHT,
  windMax: 40 * IMAGE_WEIGHT,
  windMin: 2 * IMAGE_WEIGHT,
};

export const DEPTH_DEFAULT = 0xffffff;
export const DEPTH_PURPLE = 0xffeeff;
export const DEPTH_BLUE = 0xd3def1;
export const DEPTH_GREEN = 0xddffdd;
export const DEPTH_YELLOW = 0xffffef;
export const DEPTH_ORANGE = 0xfedcbd;
export const DEPTH_RED = 0xfadbda;
