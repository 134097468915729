import * as PIXI from 'pixi.js';

import {
  DEPTH_BLUE,
  DEPTH_DEFAULT,
  DEPTH_GREEN,
  DEPTH_ORANGE,
  DEPTH_PURPLE,
  DEPTH_RED,
  DEPTH_YELLOW,
  WIND_ACCELERATION_SPEED,
  moveParam,
} from './config';

class Coin extends PIXI.Sprite {
  private posX = 0;
  private posY = 0;
  private widthSize = 0;
  private heightSize = 0;
  private myAngle = 0;

  private gravity = 0;
  private wind = 0;
  private newWind = 0;

  private aspect = 0;
  private sin = 0;

  constructor(texture: PIXI.Texture) {
    super(texture);
  }

  public setImage(moveparam: moveParam) {
    let aspect = 0;
    aspect = Math.random() * (moveparam.aspectMax - moveparam.aspectMin) + moveparam.aspectMin;

    this.posX = Math.random() * moveparam.cvsw;
    this.posY = Math.random() * moveparam.cvsh;
    this.widthSize = moveparam.imgBaseSizeW * aspect;
    this.heightSize = moveparam.imgBaseSizeH * aspect;
    this.gravity = Math.random() * (moveparam.gravityMax - moveparam.gravityMin) + moveparam.gravityMin;
    this.myAngle = Math.random() * 360;
    this.aspect = aspect;
    this.wind = moveparam.wind;
    this.newWind = moveparam.newWind;

    this.width = this.widthSize;
    this.height = this.heightSize;
    this.anchor.set(Math.random() * 0.5);

    // this.setDepthColor(aspect, moveparam);
    this.updateImages();
  }

  public flow(moveparam: moveParam) {
    if (this.newWind != this.wind) {
      this.newWind > this.wind ? (this.wind += WIND_ACCELERATION_SPEED) : (this.wind -= WIND_ACCELERATION_SPEED);
    }
    this.posX += this.wind / this.widthSize;
    this.posY += this.gravity * this.aspect;
    this.myAngle += moveparam.angleAdd;
    // this.sin = Math.sin(this.myAngle * PIXI.DEG_TO_RAD * this.aspect);

    if (this.sin < -0.99) {
      this.tint = DEPTH_DEFAULT;
    } else if (this.sin > 0.99) {
      this.tint = DEPTH_RED;
    }

    if (this.posY >= moveparam.cvsh) {
      this.posY = -this.heightSize;
    }
    if (this.posX >= moveparam.cvsw) {
      this.posX = -this.widthSize;
    }
    this.updateImages();
  }

  public setWind(newWind: number) {
    this.newWind = newWind;
  }

  private updateImages() {
    this.position.set(this.posX, this.posY);
    this.skew.x = this.sin;
    this.skew.y = this.sin / 2;
    this.angle = this.myAngle;
  }

  private setDepthColor(aspect: number, moveparam: moveParam) {
    if (aspect === moveparam.aspectMax) {
      this.tint = DEPTH_DEFAULT;
    } else if (aspect > moveparam.aspectMax * 0.7) {
      this.tint = DEPTH_DEFAULT;
    } else if (aspect > moveparam.aspectMax * 0.6) {
      this.tint = DEPTH_RED;
    } else if (aspect > moveparam.aspectMax * 0.5) {
      this.tint = DEPTH_ORANGE;
    } else if (aspect > moveparam.aspectMax * 0.4) {
      this.tint = DEPTH_YELLOW;
    } else if (aspect > moveparam.aspectMax * 0.3) {
      this.tint = DEPTH_GREEN;
    } else if (aspect > moveparam.aspectMax * 0.2) {
      this.tint = DEPTH_BLUE;
    } else {
      this.tint = DEPTH_PURPLE;
    }
  }
}
export default Coin;
