// MotionName
export enum MotionName {
  TAIKI1 = 101, // 待機1
  TAIKI2 = 102, // 待機2
  TAIKI3 = 103, // 待機3
  TAIKI4 = 104, // 待機4
  TAIKI5 = 105, // 待機5
  MIMAMORI1 = 201, // 見守り1
  MIMAMORI2 = 202, // 見守り2
  MIMAMORI3 = 203, // 見守り3
  REACTION1IN = 310, // リアクション1 IN
  REACTION1LOOP = 311, // リアクション1 LOOP
  REACTION2IN = 320, // リアクション2 IN
  REACTION2LOOP = 321, // リアクション2 LOOP
  REACTION3IN = 330, // リアクション3 IN
  REACTION3LOOP = 331, // リアクション3 LOOP
  REACTION4IN = 340, // リアクション4 IN
  REACTION4LOOP = 341, // リアクション4 LOOP
  REACTION5IN = 350, // リアクション5 IN
  REACTION5LOOP = 351, // リアクション5 LOOP
  REACTION6IN = 360, // リアクション6 IN
  REACTION6LOOP = 361, // リアクション6 LOOP
}

export const MotionTaiki: { [key: number]: MotionName } = [
  MotionName.TAIKI1,
  MotionName.TAIKI2,
  MotionName.TAIKI3,
  MotionName.TAIKI4,
  MotionName.TAIKI5,
];

export const MotionMimamori: { [key: number]: MotionName } = [
  MotionName.MIMAMORI1,
  MotionName.MIMAMORI2,
  MotionName.MIMAMORI3,
];

export const MotionReactionIn: { [key: number]: MotionName } = [
  MotionName.REACTION1IN,
  MotionName.REACTION2IN,
  MotionName.REACTION3IN,
  MotionName.REACTION4IN,
  MotionName.REACTION5IN,
  MotionName.REACTION6IN,
];

export const MotionReaction: { [key: number]: MotionName } = [
  MotionName.REACTION1LOOP,
  MotionName.REACTION2LOOP,
  MotionName.REACTION3LOOP,
  MotionName.REACTION4LOOP,
  MotionName.REACTION5LOOP,
  MotionName.REACTION6LOOP,
];

// Eye Control
export enum EyeControl {
  NON = 'non',
  FREE = 'free',
  REEL = 'reel',
}

type TypeMotionList = Record<MotionName, { motion: string[] }>;

//7.5.1 モーションリスト
export const motionList: TypeMotionList = {
  [MotionName.TAIKI1]: {
    motion: ['idle_01_a', 'idle_01_a', 'idle_01_a', 'idle_01_b', 'idle_01_a', 'idle_01_b'],
  },
  [MotionName.TAIKI2]: {
    motion: ['idle_02_a', 'idle_02_a', 'idle_02_a', 'idle_02_b', 'idle_02_a', 'idle_02_b'],
  },
  [MotionName.TAIKI3]: {
    motion: ['idle_03_a', 'idle_03_a', 'idle_03_a', 'idle_03_b', 'idle_03_a', 'idle_03_b'],
  },
  [MotionName.TAIKI4]: {
    motion: ['idle_04_a', 'idle_04_a', 'idle_04_a', 'idle_04_b', 'idle_04_a', 'idle_04_b'],
  },
  [MotionName.TAIKI5]: {
    motion: ['idle_05_a', 'idle_05_a', 'idle_05_a', 'idle_05_b', 'idle_05_a', 'idle_05_b'],
  },
  [MotionName.MIMAMORI1]: {
    motion: ['watching_01_a', 'watching_01_b', 'watching_01_a', 'watching_01_b'],
  },
  [MotionName.MIMAMORI2]: {
    motion: ['watching_02_a', 'watching_02_b', 'watching_02_a', 'watching_02_b'],
  },
  [MotionName.MIMAMORI3]: {
    motion: ['watching_03_a', 'watching_03_b', 'watching_03_a', 'watching_03_b'],
  },
  [MotionName.REACTION1IN]: {
    motion: ['reaction_01_in'],
  },
  [MotionName.REACTION1LOOP]: {
    motion: ['reaction_01_a', 'reaction_01_b'],
  },
  [MotionName.REACTION2IN]: {
    motion: ['reaction_02_in'],
  },
  [MotionName.REACTION2LOOP]: {
    motion: ['reaction_02_a', 'reaction_02_b'],
  },
  [MotionName.REACTION3IN]: {
    motion: ['reaction_03_in'],
  },
  [MotionName.REACTION3LOOP]: {
    motion: ['reaction_03_a', 'reaction_03_b'],
  },
  [MotionName.REACTION4IN]: {
    motion: ['reaction_04_in'],
  },
  [MotionName.REACTION4LOOP]: {
    motion: ['reaction_04_a', 'reaction_04_b'],
  },
  [MotionName.REACTION5IN]: {
    motion: ['reaction_05_in'],
  },
  [MotionName.REACTION5LOOP]: {
    motion: ['reaction_05_a', 'reaction_05_b'],
  },
  [MotionName.REACTION6IN]: {
    motion: ['reaction_06_in'],
  },
  [MotionName.REACTION6LOOP]: {
    motion: ['reaction_06_a', 'reaction_06_b'],
  },
};

export enum BaseGameMotionType {
  Normal,
  FreeSpin,
  BonusWin,
  BonusWinLoop,
  LvUp,
  LvUpLoop,
  LvUpLoopAf,
  CountUp,
  CountUpLoop,
  CountUpBig,
  CountUpBigLoop,
  CountUpMega,
  CountUpGreat,
  CountUpEpic,
  CountUpEpicLoop,
  TotalWin,
  TotalWinLoop,
  TotalWinLoop1,
  TotalWinLoop3,
  Non,
}
