export const BANNER_POSITION_X = -36;
export const BANNER_POSITION_Y = -36;
export const BANNER_WIDTH = 1182;
export const BANNER_HEIGHT = 743;

const TEXT_DISTANCE = 110;

export const MESSAGE_BANNER_TITLE_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_TITLE_Y = 190;
export const MESSAGE_BANNER_TITLE_SCALE = 0.8;

export const MESSAGE_BANNER_SPINS_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_SPINS_Y = MESSAGE_BANNER_TITLE_Y + TEXT_DISTANCE;
export const MESSAGE_BANNER_SPINS_SCALE = 0.6;

export const MESSAGE_BANNER_DESCRIPTION_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_DESCRIPTION_Y = MESSAGE_BANNER_SPINS_Y + TEXT_DISTANCE;

export const MESSAGE_BANNER_BTN_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_BTN_Y = MESSAGE_BANNER_DESCRIPTION_Y + TEXT_DISTANCE;
export const MESSAGE_BANNER_BTN_SCALE = 0.7;

export const MESSAGE_BANNER_MAX_LEVEL_TITLE_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_MAX_LEVEL_TITLE_Y = 115;
export const MESSAGE_BANNER_MAX_LEVEL_TITLE_SCALE = 0.8;

export const MESSAGE_BANNER_MAX_LEVEL_SPINS_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_MAX_LEVEL_SPINS_Y = 200;
export const MESSAGE_BANNER_MAX_LEVEL_SPINS_SCALE = 0.5;

export const MESSAGE_BANNER_MAX_LEVEL_BTN_X = BANNER_WIDTH / 2;
export const MESSAGE_BANNER_MAX_LEVEL_BTN_Y = 610;
export const MESSAGE_BANNER_MAX_LEVEL_BTN_SCALE = 0.8;

export const MESSAGE_BANNER_MAX_ARROW_SPRITE_X = 650;
export const MESSAGE_BANNER_MAX_ARROW_SPRITE_Y = 400;
export const MESSAGE_BANNER_MAX_ARROW_SPRITE_SCALE = 0.7;

export const MESSAGE_BANNER_MAX_SYMBOL_B_X = 250;
export const MESSAGE_BANNER_MAX_SYMBOL_B_Y = 310;
export const MESSAGE_BANNER_MAX_SYMBOL_C_X = 450;
export const MESSAGE_BANNER_MAX_SYMBOL_C_Y = 310;
export const MESSAGE_BANNER_MAX_SYMBOL_D_X = 250;
export const MESSAGE_BANNER_MAX_SYMBOL_D_Y = 475;
export const MESSAGE_BANNER_MAX_SYMBOL_E_X = 450;
export const MESSAGE_BANNER_MAX_SYMBOL_E_Y = 475;
export const MESSAGE_BANNER_MAX_LEFT_SYMBOL_SPRITE_SCALE = 0.9;

export const MESSAGE_BANNER_MAX_SYMBOL_A_X = 900;
export const MESSAGE_BANNER_MAX_SYMBOL_A_Y = 400;
export const MESSAGE_BANNER_MAX_SYMBOL_A_SCALE = 1.3;

export const RETRIGGER_MESSAGE_BANNER_TITLE_Y = 150;
export const RETRIGGER_MESSAGE_BANNER_ARROW_Y = 350;
export const RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_Y = 350;
export const RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_X_MARGIN = -350;
export const RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_Y = 350;
export const RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_X_MARGIN = 330;
export const RETRIGGER_MESSAGE_BANNER_DESCRIPTION_Y = 600;

export const WIN_MESSAGE_BANNER_TITLE_Y = 275;
export const WIN_MESSAGE_BANNER_DESCRIPTION_Y = 400;
export const WIN_MESSAGE_BANNER_FREE_SPIN_AMOUNT_Y = 600;
