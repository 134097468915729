import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { MessageBannerProps } from '../../global';
import { setBrokenGame } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  BANNER_WIDTH,
  WIN_MESSAGE_BANNER_DESCRIPTION_Y,
  WIN_MESSAGE_BANNER_FREE_SPIN_AMOUNT_Y,
  WIN_MESSAGE_BANNER_TITLE_Y,
} from './config';
import { freeSpinsTotalSpinsStyles, freeSpinsTotalWinTitleStyles, winTotalSpinsTextStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageBannerProps & {
    totalWin: string;
    totalSpins: number;
    level: number;
  };

  private freeSpinsAmount: PIXI.Text;

  constructor(
    props: MessageBannerProps & {
      totalWin: string;
      totalSpins: number;
      level: number;
    },
  ) {
    super(props);
    this.props = props;
    this.freeSpinsAmount = this.initFreeSpinsAmount();
  }

  public override init(): MessageWinBanner {
    super.init();
    if (this.props.level === 5 && !setBrokenGame()) {
      this.addChild(this.freeSpinsAmount);
    }
    return this;
  }

  protected override initBanner(): PIXI.Sprite {
    const winBanner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    return winBanner;
  }

  protected override initTitle(_titleText?: string): PIXI.Text {
    const winTitle = new PIXI.Text(`${i18n.t('freeSpinsTotalWinTitle' || '')}`, freeSpinsTotalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.visible = !!winTitle;

    winTitle.anchor.set(0.5, 0.5);
    winTitle.y = WIN_MESSAGE_BANNER_TITLE_Y;
    winTitle.x = BANNER_WIDTH / 2;
    updateTextScale(winTitle, isMobile ? 700 : 750, isMobile ? 350 : 250);
    return winTitle;
  }

  protected override initDescription(_descriptionText?: string): PIXI.Text {
    const winTotalSpins = new PIXI.Text(`${this.props.totalWin}`, winTotalSpinsTextStyles);
    winTotalSpins.resolution = 1;
    winTotalSpins.visible = !!winTotalSpins;

    winTotalSpins.anchor.set(0.5, 0.5);
    winTotalSpins.y = WIN_MESSAGE_BANNER_DESCRIPTION_Y;
    winTotalSpins.x = BANNER_WIDTH / 2;
    return winTotalSpins;
  }

  protected initFreeSpinsAmount(): PIXI.Text {
    const winSubTitle = new PIXI.Text(
      `${this.props.totalSpins} ${i18n.t('freeSpinsTotalSpins' || '')}`,
      freeSpinsTotalSpinsStyles,
    );
    winSubTitle.resolution = 1;
    winSubTitle.anchor.set(0.5, 0.5);
    updateTextScale(winSubTitle, isMobile ? 700 : 750, isMobile ? 350 : 250);
    winSubTitle.y = WIN_MESSAGE_BANNER_FREE_SPIN_AMOUNT_Y;
    winSubTitle.x = BANNER_WIDTH / 2;
    winSubTitle.visible = !!winSubTitle;
    return winSubTitle;
  }

  protected override initBtn(_btnText?: string): PIXI.Text {
    const btn = new PIXI.Text('');
    btn.resolution = 1;
    return btn;
  }
}
