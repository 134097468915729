import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { setGameMode } from '../../../gql/cache';
import i18n from '../../../i18next';
import { ResourceTypes } from '../../../resources.d';
import { updateTextScale } from '../../../utils/utils';
import ViewContainer from '../../components/container';
import { eventManager } from '../../config';
import { FREESPINS_PANEL_MARGIN_X } from '../config';
import { maxLevelTextStyle } from '../textStyles';

import { LevelIndicaterSymbol } from './levelIndicaterSymbol';

export class LevelIndicater extends ViewContainer {
  private text: PIXI.Text;

  private indicatorList: LevelIndicaterSymbol[] = [];

  private levelUpHandler = this.onLevelUp.bind(this);

  private bindedShowMaxLevelLabel = this.showMaxLevelLabel.bind(this);

  constructor() {
    super();

    this.indicatorList = this.initIndicator(setGameMode());
    this.text = this.initText(setGameMode() === GameMode.FREE_SPINS_LVL5);

    eventManager.addListener(EventTypes.SET_NEXT_FREE_SPINS_LEVEL, this.levelUpHandler);
    eventManager.addListener(EventTypes.SHOW_MAX_LEVEL_LABEL, this.bindedShowMaxLevelLabel);
  }

  private onLevelUp(settings: { mode: GameMode }): void {
    if (this.indicatorList.length === 0) return;

    if (settings.mode <= GameMode.FREE_SPINS_LVL2) {
      this.addChild(this.indicatorList[0]!);
      this.addChild(this.text);
    } else if (settings.mode <= GameMode.FREE_SPINS_LVL3) {
      this.addChild(this.indicatorList[1]!);
      this.addChild(this.text);
    } else if (settings.mode <= GameMode.FREE_SPINS_LVL4) {
      this.addChild(this.indicatorList[2]!);
      this.addChild(this.text);
    } else if (settings.mode <= GameMode.FREE_SPINS_LVL5) {
      this.addChild(this.indicatorList[3]!);
      this.addChild(this.text);
    } else {
      this.addChild(this.indicatorList[0]!);
      this.addChild(this.indicatorList[1]!);
      this.addChild(this.indicatorList[2]!);
      this.addChild(this.indicatorList[3]!);
      this.addChild(this.text);
    }
  }

  private initIndicator(currMode: GameMode): LevelIndicaterSymbol[] {
    let posX = 0;
    return [
      {
        symbol: ResourceTypes.symbolIndicatorE,
        level: GameMode.FREE_SPINS_LVL2,
      },
      {
        symbol: ResourceTypes.symbolIndicatorD,
        level: GameMode.FREE_SPINS_LVL3,
      },
      {
        symbol: ResourceTypes.symbolIndicatorC,
        level: GameMode.FREE_SPINS_LVL4,
      },
      {
        symbol: ResourceTypes.symbolIndicatorB,
        level: GameMode.FREE_SPINS_LVL5,
      },
    ].map((i) => {
      const item = new LevelIndicaterSymbol(i.symbol, i.level, currMode);
      this.addChild(item);
      item.x = posX;
      posX += item.width + FREESPINS_PANEL_MARGIN_X;

      return item;
    });
  }

  private initText(visible: boolean): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('freeSpinsIndicatorMaxLevel'), maxLevelTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.x = 400;
    text.y = 200;
    updateTextScale(text, isMobile ? 450 : 500, isMobile ? 100 : 250);

    text.position.set(this.width / 2, this.height / 2);
    text.visible = visible;
    this.addChild(text);

    return text;
  }

  private showMaxLevelLabel() {
    this.text.visible = true;
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    this.indicatorList = [];
    eventManager.removeListener(EventTypes.SHOW_MAX_LEVEL_LABEL, this.bindedShowMaxLevelLabel);
  }
}
