import { countDownAnimationType, phoenixAnimationType } from './table';
import { getRandomFromUUID, getResultFromTable } from './util';

export function conversionPhoenixAnimationType(num: number): phoenixAnimationType {
  let animType = phoenixAnimationType.NON;
  switch (num) {
    case 0:
      animType = phoenixAnimationType.PHOENIX;
      break;
    case 1:
      animType = phoenixAnimationType.NON;
      break;
  }
  return animType;
}

export function conversionCountDownAnimationType(num: number): countDownAnimationType {
  let animType = countDownAnimationType.DEFAULT;
  switch (num) {
    case 0:
      animType = countDownAnimationType.RAINBOW;
      break;
    case 1:
      animType = countDownAnimationType.DEFAULT;
      break;
  }
  return animType;
}

export function getAnimationSelectionTableLot(uuid: string, table: number[]): number {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(table, rand);
  return result;
}
