import { BonusStatus, GameMode, UserBonus, bonusIds } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setIsMaxLevelFreeSpinsFeature,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotHistoryData } from '../../gql/d';
import { getUserBonuses, userBonusBetsGql } from '../../gql/query';
import { getGameModeByBonusId, getLevelByGameMode } from '../../utils';

export const checkBrokenGame = async () => {
  const activeUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });

  if (activeUserBonusData.data.userBonuses.length > 0) {
    if (
      activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE] ||
      activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE_MAX]
    ) {
      setBrokenBuyFeature(true);
      setIsBuyFeaturePopupOpened(true);
      if (activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE_MAX]) {
        setIsMaxLevelFreeSpinsFeature(true);
      }
      setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
      });
    } else {
      setBrokenGame(true);
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        totalRounds: 0,
      });
      setCurrentFreeSpinsTotalWin(0);
      const userBonusBetsData = await client.query<{
        bets: ISlotHistoryData;
      }>({
        query: userBonusBetsGql,

        variables: {
          input: {
            filter: {
              userBonusId: setCurrentBonus().id,
            },
          },
        },
        fetchPolicy: 'network-only',
      });

      if (getLevelByGameMode(getGameModeByBonusId(setCurrentBonus().bonusId)) === 5) {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: 8 - setCurrentBonus().rounds,
          rounds: 8,
        });
      } else {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: userBonusBetsData.data.bets.pageInfo.count,
          rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
          totalWinAmount: 0,
        });
      }
    }
  }
};
