import { ResourceTypes } from '../../resources.d';

import {
  buyFeatureBtnTitleDisableStyle,
  buyFeatureBtnTitleStyle,
  buyFeatureMaxLevelTitleDisableStyle,
  buyFeatureMaxLevelTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

export enum BtnType {
  NormalBtn,
  MaxBtn,
}

export const BUY_FEATURE_NORMAL_BTN = [ResourceTypes.buyFeatureFsBtn, ResourceTypes.buyFeatureFsBtnNotActive];

export const BUY_FEATURE_MAX_BTN = [ResourceTypes.buyFeatureMlfsBtn, ResourceTypes.buyFeatureMlfsBtnNotActive];

export const BUY_FEATURE_BTN_TITLE_TEXT_STYLE = [buyFeatureBtnTitleStyle, buyFeatureBtnTitleDisableStyle];
export const BUY_FEATURE_MAX_LEVEL_TEXT_STYLE = [buyFeatureMaxLevelTitleStyle, buyFeatureMaxLevelTitleDisableStyle];
export const BUY_FEATURE_COST_TEXT_STYLE = [totalCostTextAmountStyle, totalCostTextAmountDisableStyle];
