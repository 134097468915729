import _ from 'lodash';

import { SlotId } from '../../config';
import { FeatureTypes, GameMode, IBonus, ISettledBet, bonusIds } from '../../global.d';
import { setBonuses, setNextResult } from '../../gql/cache';

export const getCoinAmount = (nextResult: ISettledBet, isScatter: boolean, isExpand: boolean): number => {
  const payLine = nextResult.paylines;

  let rtn = 0;

  payLine.forEach((line) => {
    if (isExpand) {
      if (line.lineId == null) {
        // expand win
        if (
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id != SlotId.WL
        ) {
          rtn = line.amount * 10;
        }
      }
    } else {
      if (line.lineId != null) {
        // normal win
        if (line.winPositions.length > 0) {
          rtn += line.amount;
        }
      } else {
        if (
          isScatter &&
          line.winPositions.length > 0 &&
          setNextResult()?.bet.result.spinResult[line.winPositions[0]!]!.id === SlotId.WL
        ) {
          // scatter win
          rtn += line.amount * 10;
        }
      }
    }
  });
  return rtn;
};

export const getNormalCoinAmount = (): number => {
  const bonuses = setBonuses();
  const bonus = _.chain(bonuses)
    .filter((bonus) => bonus.type === FeatureTypes.FREE_SPIN && bonus.id === bonusIds[GameMode.BUY_FEATURE])
    .get(0, {})
    .value() as IBonus;

  return bonus.coinAmount;
};

export const getMaxCoinAmount = (): number => {
  const bonuses = setBonuses();
  const bonus = _.chain(bonuses)
    .filter((bonus) => bonus.type === FeatureTypes.FREE_SPIN && bonus.id === bonusIds[GameMode.BUY_FEATURE_MAX])
    .get(0, {})
    .value() as IBonus;

  return bonus.coinAmount;
};
