import * as PIXI from 'pixi.js';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { infoFeatureBuy1 } from '../../config/gameRules';
import { FeatureState } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import SlotMachine from '../../slotMachine';
import { getMaxCoinAmount, getNormalCoinAmount } from '../../utils';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }

  return (
    <section className={styles['feature']}>
      <section className={styles['feature']}>
        <h1 className={styles['title']}>{t('infoFeatureFreeSpinTitle')}</h1>
        <div
          className={`${styles['p']} ${styles['margin']}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc1') }}
        />
        <div
          className={`${styles['p']} ${styles['margin']}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc2') }}
        />
        <div
          className={`${styles['p']} ${styles['margin']}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc3') }}
        />
        <ul>
          <li
            className={`${styles['p']} ${styles['left']}`}
            dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel1') }}
          />
          <li
            className={`${styles['p']} ${styles['left']}`}
            dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel2') }}
          />
          <li
            className={`${styles['p']} ${styles['left']}`}
            dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel3') }}
          />
          <li
            className={`${styles['p']} ${styles['left']}`}
            dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel4') }}
          />
          <li
            className={`${styles['p']} ${styles['left']}`}
            dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel5') }}
          />
        </ul>
        <div
          className={`${styles['p']} ${styles['left']}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinNextDesc') }}
        />
        <div
          className={`${styles['p']} ${styles['left']}`}
          dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinNext') }}
        />
      </section>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const multiplier = getNormalCoinAmount();
  const maxMultiplier = getMaxCoinAmount();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles['image']}>
        <img draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoFeatureBuy1.map(({ key, value }) => (
            <p className={styles['p']} key={key}>
              {value === undefined && <div dangerouslySetInnerHTML={{ __html: t(key) }} />}
              {value === FeatureState.NORMAL && <div dangerouslySetInnerHTML={{ __html: t(key, { multiplier }) }} />}
              {value === FeatureState.MAX && <div dangerouslySetInnerHTML={{ __html: t(key, { maxMultiplier }) }} />}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <FreeSpinFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
