import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
  [SlotId.K]: ResourceTypes.symbolK,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.SC1]: ResourceTypes.symbolSc,
  [SlotId.SC2]: ResourceTypes.symbolSc,
  [SlotId.SC3]: ResourceTypes.symbolSc,
  [SlotId.SC4]: ResourceTypes.symbolSc,
  [SlotId.SC5]: ResourceTypes.symbolSc,
  [SlotId.SC6]: ResourceTypes.symbolSc,
  [SlotId.SC7]: ResourceTypes.symbolSc,
};
export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.H]: ResourceTypes.symbolHBlur,
  [SlotId.I]: ResourceTypes.symbolIBlur,
  [SlotId.J]: ResourceTypes.symbolJBlur,
  [SlotId.K]: ResourceTypes.symbolKBlur,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.SC1]: ResourceTypes.symbolSc,
  [SlotId.SC2]: ResourceTypes.symbolSc,
  [SlotId.SC3]: ResourceTypes.symbolSc,
  [SlotId.SC4]: ResourceTypes.symbolSc,
  [SlotId.SC5]: ResourceTypes.symbolSc,
  [SlotId.SC6]: ResourceTypes.symbolSc,
  [SlotId.SC7]: ResourceTypes.symbolSc,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC7]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'a_symbol_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'b_symbol_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'c_symbol_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'd_symbol_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'e_symbol_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'f_symbol_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'g_symbol_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'h_symbol_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'i_symbol_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'j_symbol_win',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'k_symbol_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'wild_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC7]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.logo,
  ResourceTypes.titlelogo,
  ResourceTypes.infoPaylines,
  ResourceTypes.buttonCancel,
  ResourceTypes.buttonOk,

  ResourceTypes.intro1,
  ResourceTypes.intro2,
  ResourceTypes.intro3,
  ResourceTypes.introBg,

  ResourceTypes.slotBg,
  ResourceTypes.slotFreespinBgState1,
  ResourceTypes.slotFreespinBgState2,
  ResourceTypes.slotFreespinBgState3,

  ResourceTypes.reelback,
  ResourceTypes.reelframe,
  ResourceTypes.symbolIndicatorA,
  ResourceTypes.symbolIndicatorB,
  ResourceTypes.symbolIndicatorC,
  ResourceTypes.symbolIndicatorD,
  ResourceTypes.symbolIndicatorE,

  ResourceTypes.symbolA,
  ResourceTypes.symbolB,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolC,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolD,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolE,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolF,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolG,
  ResourceTypes.symbolGBlur,
  ResourceTypes.symbolH,
  ResourceTypes.symbolHBlur,
  ResourceTypes.symbolI,
  ResourceTypes.symbolIBlur,
  ResourceTypes.symbolJ,
  ResourceTypes.symbolJBlur,
  ResourceTypes.symbolK,
  ResourceTypes.symbolKBlur,
  ResourceTypes.symbolWl,
  ResourceTypes.symbolSc,

  ResourceTypes.levelPanel,
  ResourceTypes.popupBg,
  ResourceTypes.messageBanerPointer,
  ResourceTypes.messageBanner,

  ResourceTypes.backdrop,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnPressed,
  ResourceTypes.buyFeatureBtnDisable,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureCancelBtnDisable,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtnPressed,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureMinusBtnDisable,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtnPressed,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeatureOkBtnDisable,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtnPressed,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePlusBtnDisable,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtnPressed,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.buyFeatureMoon,
  ResourceTypes.buyFeatureSakura,
  ResourceTypes.buyFeatureFsBtnHover,
  ResourceTypes.buyFeatureFsBtnNotActive,
  ResourceTypes.buyFeatureFsBtnPressed,
  ResourceTypes.buyFeatureFsBtn,
  ResourceTypes.buyFeatureMlfsBtnHover,
  ResourceTypes.buyFeatureMlfsBtnNotActive,
  ResourceTypes.buyFeatureMlfsBtnPressed,
  ResourceTypes.buyFeatureMlfsBtn,

  ResourceTypes.petalA,
  ResourceTypes.petalB,
  ResourceTypes.petalC,
  ResourceTypes.coin1,
  ResourceTypes.coin4,
  ResourceTypes.coin8,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'ambient',
      url: generateTexturePath('/animations', 'ambient/ambient.json', isMobile),
    },
    {
      name: 'nearmiss',
      url: generateTexturePath('/animations', 'nearmiss/nearmiss.json', isMobile),
    },
    {
      name: 'all_symbols',
      url: generateTexturePath('/animations', 'all_symbols/all_symbols.json', isMobile),
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json'),
    },
    {
      name: 'fsCountDown',
      url: generateTexturePath('/animations', 'fs_countdown/fs_countdown.json', isMobile),
    },
    {
      name: 'fsTransition',
      url: generateTexturePath('/animations', 'fs_transition/fs_transition.json', isMobile),
    },
    {
      name: 'avatar_Mr.First',
      url: generateTexturePath('/animations', 'avatar_Mr.First/avatar_Mr.First.json'),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
