import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import i18n from '../../../i18next';
import { ResourceTypes } from '../../../resources.d';
import { nextTick, updateTextScale } from '../../../utils';
import AnimationGroup from '../../animations/animationGroup';
import SpineAnimation from '../../animations/spine';
import Tween from '../../animations/tween';
import ViewContainer from '../../components/container';
import { eventManager } from '../../config';
import { FS_TRANSITION_ANIMATION_NAME, FS_TRANSITION_INDICATOR_ANIMATION } from '../config';
import { levelTextStyle } from '../textStyles';

export class LevelIndicaterSymbol extends ViewContainer {
  private readonly level: GameMode;

  private symbol: PIXI.Sprite;

  private text: PIXI.Text;

  private levelUpHandler = this.onLevelUp.bind(this);

  private bindedHideLevelLabels = this.hideLevelLabels.bind(this);

  constructor(resource: ResourceTypes, level: GameMode, currLevel: GameMode) {
    super();

    this.level = level;
    this.symbol = this.initSymbol(level <= currLevel ? ResourceTypes.symbolIndicatorA : resource);
    this.text = this.initText(level <= currLevel && currLevel < GameMode.FREE_SPINS_LVL5);

    eventManager.addListener(EventTypes.SET_NEXT_FREE_SPINS_LEVEL, this.levelUpHandler);
    eventManager.addListener(EventTypes.SHOW_MAX_LEVEL_LABEL, this.bindedHideLevelLabels);
  }

  private hideLevelLabels(): void {
    this.text.visible = false;
  }

  private initSymbol(resource: ResourceTypes): PIXI.Sprite {
    const symbol = new PIXI.Sprite(PIXI.Texture.from(resource));
    symbol.anchor.set(0.5, 0.5);
    symbol.position.set(symbol.width / 2, symbol.height / 2);
    this.addChild(symbol);
    return symbol;
  }

  private initText(visible: boolean): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('freeSpinsIndicatorLevel', { level: this.level }), levelTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.symbol.width / 2, this.symbol.height / 2);
    updateTextScale(text, isMobile ? 120 : 130, isMobile ? 100 : 250);

    text.rotation = -Math.PI / 6;
    text.visible = visible;
    this.addChild(text);

    return text;
  }

  private onLevelUp(settings: { mode: GameMode }): void {
    if (settings.mode === this.level) {
      this.replaceToShark();
    }
    if (this.level < settings.mode) {
      this.text.visible = true;
    }
  }

  private replaceToShark() {
    const group = new AnimationGroup();
    const symbolTransitionAnimation = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources[FS_TRANSITION_ANIMATION_NAME]!.spineData,
    );
    const delay = Tween.createDelayAnimation(200);
    delay.addOnComplete(() => {
      this.symbol.texture = PIXI.Texture.from(ResourceTypes.symbolIndicatorA);
    });

    symbolTransitionAnimation.getSpine().position.set(this.symbol.width / 2, this.symbol.height / 2);

    symbolTransitionAnimation.addOnStart(() => {
      this.addChild(symbolTransitionAnimation.getSpine());
      symbolTransitionAnimation.setAnimation(FS_TRANSITION_INDICATOR_ANIMATION, false);
    });
    symbolTransitionAnimation.addOnComplete(() => {
      nextTick(() => {
        this.removeChild(symbolTransitionAnimation.getSpine());
        if (this.level < GameMode.FREE_SPINS_LVL5) {
          this.text.visible = true;
        } else {
          eventManager.emit(EventTypes.SHOW_MAX_LEVEL_LABEL);
        }
      });
    });
    group.addAnimation(symbolTransitionAnimation);
    group.addAnimation(delay);
    group.start();
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.SET_NEXT_FREE_SPINS_LEVEL, this.levelUpHandler);
    eventManager.removeListener(EventTypes.SHOW_MAX_LEVEL_LABEL, this.bindedHideLevelLabels);
  }
}
