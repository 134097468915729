import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { MessageBannerProps } from '../../global';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  BANNER_WIDTH,
  RETRIGGER_MESSAGE_BANNER_ARROW_Y,
  RETRIGGER_MESSAGE_BANNER_DESCRIPTION_Y,
  RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_X_MARGIN,
  RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_Y,
  RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_X_MARGIN,
  RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_Y,
  RETRIGGER_MESSAGE_BANNER_TITLE_Y,
} from './config';
import { freeSpinsRetriggerDescriptionStyles, freeSpinsTitleLevelStyles } from './textStyles';

export class RetriggerMessageBanner extends BaseMessageBanner {
  protected override props: MessageBannerProps & {
    level: number;
    lastLevelRetriger: boolean;
  };

  constructor(props: MessageBannerProps & { level: number; lastLevelRetriger: boolean }) {
    super(props);
    this.props = props;
  }

  public override init(): BaseMessageBanner {
    super.init();
    if (!this.props.lastLevelRetriger) {
      this.addChild(this.initLeftSymbol());
      this.addChild(this.initArrow());
      this.addChild(this.initRightSymbol());
    }
    return this;
  }

  private getCurrentLvlSymbol(): PIXI.Sprite {
    if (this.props.level === 2) {
      return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolE));
    }
    if (this.props.level === 3) {
      return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolD));
    }
    if (this.props.level === 4) {
      return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolC));
    }
    return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolB));
  }

  protected override initTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(
      `${i18n.t('freeSpinsTitleLevel' || '')} ${this.props.level} `,
      freeSpinsTitleLevelStyles,
    );
    title.resolution = 1;
    title.anchor.set(0.5);
    title.x = BANNER_WIDTH / 2;
    title.y = RETRIGGER_MESSAGE_BANNER_TITLE_Y;
    title.visible = !!titleText;
    updateTextScale(title, this.banner!.width - 200, 250);

    return title;
  }

  private initArrow(): PIXI.Sprite {
    const sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanerPointer));

    sprite.anchor.set(0.5);
    sprite.x = BANNER_WIDTH / 2;
    sprite.y = RETRIGGER_MESSAGE_BANNER_ARROW_Y;
    return sprite;
  }

  private initLeftSymbol(): PIXI.Sprite {
    const symbol = this.getCurrentLvlSymbol();
    symbol.anchor.set(0.5);
    symbol.width = 350;
    symbol.height = 250;
    symbol.x = BANNER_WIDTH / 2 + RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_X_MARGIN;
    symbol.y = RETRIGGER_MESSAGE_BANNER_LEFT_SYMBOL_Y;

    return symbol;
  }

  private initRightSymbol(): PIXI.Sprite {
    const symbol = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolA));
    symbol.anchor.set(0.5);
    symbol.width = 350;
    symbol.height = 250;
    symbol.x = BANNER_WIDTH / 2 + RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_X_MARGIN;
    symbol.y = RETRIGGER_MESSAGE_BANNER_RIGHT_SYMBOL_Y;

    return symbol;
  }

  protected override initDescription(descriptionText?: string): PIXI.Text {
    const description = new PIXI.Text(
      `${i18n.t('freeSpinsRetriggerDescription1' || '')}`,
      freeSpinsRetriggerDescriptionStyles,
    );
    const description2 = new PIXI.Text(
      `${i18n.t('freeSpinsRetriggerDescription2' || '')}`,
      freeSpinsRetriggerDescriptionStyles,
    );
    const numberOfSpins = this.props.level === 5 ? description2 : description;

    description.resolution = 1;
    description.visible = !!descriptionText;
    description.anchor.set(0.5);
    description.x = BANNER_WIDTH / 2;
    description.y = RETRIGGER_MESSAGE_BANNER_DESCRIPTION_Y;
    updateTextScale(description, isMobile ? 700 : 900, isMobile ? 250 : 350);

    description2.resolution = 1;
    description2.visible = !!descriptionText;
    description2.anchor.set(0.5);
    description2.x = BANNER_WIDTH / 2;
    description2.y = RETRIGGER_MESSAGE_BANNER_DESCRIPTION_Y;
    updateTextScale(description2, isMobile ? 700 : 900, isMobile ? 250 : 350);

    return numberOfSpins;
  }

  protected override initBtn(_btnText?: string): PIXI.Text {
    const btn = new PIXI.Text('');
    btn.resolution = 1;
    return btn;
  }
}
