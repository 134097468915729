import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BANNER_POSITION_X, BANNER_POSITION_Y } from './config';
import { btnTextStyle, descriptionTextStyle, titleTextStyle } from './textStyles';

export class BaseMessageBanner extends ViewContainer {
  protected banner: PIXI.Sprite | undefined;

  protected title: PIXI.Text | undefined;

  protected description: PIXI.Text | undefined;

  protected btn: PIXI.Text | undefined;

  protected props: MessageBannerProps;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: MessageBannerProps) {
    super();

    this.props = props;
  }

  protected init(): BaseMessageBanner {
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.visible = true;
    this.interactive = true;
    this.banner = this.initBanner();
    this.title = this.initTitle(this.props.title);
    this.description = this.initDescription(this.props.subTitle);
    this.btn = this.initBtn(this.props.btnText);
    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    this.addChild(this.banner);
    this.banner.addChild(this.title);
    this.banner.addChild(this.description);
    this.banner.addChild(this.btn);
    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }
    setIsOpenedMessageBanner(true);
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    return banner;
  }

  protected initTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>(titleText || ''), titleTextStyle);
    title.resolution = 1;
    title.visible = !!titleText;
    return title;
  }

  protected initDescription(descriptionText?: string): PIXI.Text {
    const description = new PIXI.Text(i18n.t<string>(descriptionText || ''), descriptionTextStyle);
    description.resolution = 1;
    description.visible = !!descriptionText;
    return description;
  }

  protected initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(i18n.t<string>(btnText || 'Tap to START'), btnTextStyle);
    btn.resolution = 1;
    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
    setIsOpenedMessageBanner(false);
  }
}
