import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '022_01_BGM_BG_Base',
  BaseGameBGM_Melo = '022_01_BGM_BG_Melo',
  BGM_FS1_Loop = '022_03_BGM_FS1_Loop',
  BGM_FS1_Intro = '022_03_BGM_FS1_intro',
  BGM_FS2_Loop = '022_05_BGM_FS2_Loop',
  BGM_FS3_Loop = '022_07_BGM_FS3_Loop',
  BGM_FS4_Loop = '022_09_BGM_FS4_Loop',
  BGM_FS5_Loop = '022_11_BGM_FS5_Loop',
  BigWin_Loop = '022_12_BigWin_Loop',
  BigWin_End = '022_13_BigWin_End',
  SFX_WIN_BIG = '022_14_Win_Big',
  SFX_WIN_EPIC = '022_15_Win_Mega',
  SFX_WIN_GREAT = '022_16_Win_Great',
  SFX_WIN_MEGA = '022_17_Win_Epic',
  Win_Loop = '022_18_Win_Loop',
  Win_End = '022_19_Win_End',
  FeatureTrigger = '022_20_FeatureTrigger',
  TotalWinBanner = '022_21_TotalWinBanner',
  Scatter_01 = '022_22_Scatter_01',
  Scatter_02 = '022_23_Scatter_02',
  Scatter_03 = '022_24_Scatter_03',
  Scatter_04 = '022_25_Scatter_04',
  Scatter_05 = '022_26_Scatter_05',
  LongSpin = '022_27_LongSpin',
  LvUp = '022_28_LvUp',
  LvUpBanner = '022_29_LvUpBanner',
  LvUpIconSE = '022_30_LvUpIconSE',
  SE_Yocho_Phoenix = 'SE_Yocho_Phoenix',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
}

export enum ISongsVoice {}

export const audioSprite: TAudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 93913.08390022675, true],
  [ISongs.BaseGameBGM_Melo]: [95000, 93913.08390022675, true],
  [ISongs.BGM_FS1_Loop]: [190000, 15238.140589569155, true],
  [ISongs.BGM_FS1_Intro]: [207000, 15238.140589569155],
  [ISongs.BGM_FS2_Loop]: [224000, 14769.251700680286, true],
  [ISongs.BGM_FS3_Loop]: [240000, 14328.390022675734, true],
  [ISongs.BGM_FS4_Loop]: [256000, 13913.083900226753, true],
  [ISongs.BGM_FS5_Loop]: [271000, 70000, true],
  [ISongs.BigWin_Loop]: [342000, 13913.06122448981, true],
  [ISongs.BigWin_End]: [357000, 2478.9342403628325],
  [ISongs.SFX_WIN_BIG]: [361000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [364000, 1696.1678004535088],
  [ISongs.SFX_WIN_GREAT]: [367000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [371000, 1862.2675736961583],
  [ISongs.Win_Loop]: [374000, 399.7505668934309, true],
  [ISongs.Win_End]: [376000, 933.4693877551103],
  [ISongs.FeatureTrigger]: [378000, 1033.3333333333599],
  [ISongs.TotalWinBanner]: [381000, 3497.1882086167625],
  [ISongs.Scatter_01]: [386000, 1053.6281179138314],
  [ISongs.Scatter_02]: [389000, 1003.3333333333303],
  [ISongs.Scatter_03]: [392000, 1037.4603174603294],
  [ISongs.Scatter_04]: [395000, 1015.6009070294658],
  [ISongs.Scatter_05]: [398000, 1021.8820861678068],
  [ISongs.LongSpin]: [401000, 1998.7528344670977],
  [ISongs.LvUp]: [404000, 801.5873015872899],
  [ISongs.LvUpBanner]: [406000, 3133.0839002267794],
  [ISongs.LvUpIconSE]: [411000, 1486.802721088452],
  [ISongs.SE_Yocho_Phoenix]: [414000, 4033.33333333336],
  [ISongs.SFX_UI_BetChange]: [420000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [422000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [424000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [426000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [428000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [430000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [432000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [434000, 151.02040816327644],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.2,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  [ISongs.BGM_FS1_Loop]: 0.3,
  [ISongs.BGM_FS1_Intro]: 0.3,
  [ISongs.BGM_FS2_Loop]: 0.3,
  [ISongs.BGM_FS3_Loop]: 0.3,
  [ISongs.BGM_FS4_Loop]: 0.3,
  [ISongs.BGM_FS5_Loop]: 0.3,
  [ISongs.BigWin_Loop]: 0.15,
  [ISongs.BigWin_End]: 0.15,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.TotalWinBanner]: 0.3,
  [ISongs.Scatter_01]: 0.3,
  [ISongs.Scatter_02]: 0.3,
  [ISongs.Scatter_03]: 0.3,
  [ISongs.Scatter_04]: 0.3,
  [ISongs.Scatter_05]: 0.3,
  [ISongs.LongSpin]: 0.3,
  [ISongs.LvUp]: 0.3,
  [ISongs.LvUpBanner]: 0.3,
  [ISongs.LvUpIconSE]: 0.3,
  [ISongs.SE_Yocho_Phoenix]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
